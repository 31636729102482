<template>
    <div id="index" ref="appRef">
        <div class="bg">
            <dv-loading v-if="loading">Loading...</dv-loading>
            <div v-else class="all-container">
                <div class="all-header">
                    <div class="date-box"><span class="one"> {{ dateYear }}</span> <span
                            class="two"> {{ dateWeek }}</span> <span class="three"> {{ dateDay }}</span></div>
                    <div class="date-box"></div>
                </div>
                <!-- 主要大屏部分 -->
                <div class="all-screen">
                    <div class="up-box flex ac jb">
                        <div class="ll-box">
                            <div class="llOne-box pdlf24 flex ac jb">
                                <div class="pro-num">
                                    <p>项目数（个）</p>
                                    <p></p>
                                    <p>235</p>
                                </div>
                                <div class="pro-num">
                                    <p>总投资数（亿）</p>
                                    <p></p>
                                    <p>844.52</p>
                                </div>

                            </div>
                            <img class="ztfl-title" src="../assets/infoBack.png">
                            <div class="llTwo-box flex ac">
                                <div class="left">
                                    <Echart
                                            :options="options1"
                                            id="LeftChartOne"
                                            height="140px"
                                            width="140px"
                                    ></Echart>
                                </div>
                                <div class="right">
                                    <div class="pt1 flex ac">
                                        <p class="mr10"></p>
                                        <p>平台：</p>
                                        <p>135个（57%）</p>
                                    </div>
                                    <div class="flex zj1 ac">
                                        <p class="mr10"></p>
                                        <p>镇街：</p>
                                        <p>39个（17%）</p>
                                    </div>
                                    <div class="flex bm1 ac">
                                        <p class="mr10"></p>
                                        <p>部门：</p>
                                        <p>61个（26%）</p>
                                    </div>
                                </div>
                            </div>
                            <img class="xmtz-title" src="../assets/infoBack.png">
                            <div class="llthree-box">
                                <Echart
                                        :options="options2"
                                        id="Chart2"
                                        height="170px"
                                        width="392px"
                                ></Echart>
                            </div>
                        </div>
                        <div class="mm1-box">
                            <img class="xmjd-title" src="../assets/infoBack.png">
                            <div class="all-proNum flex ac jb fw">
                                <div class="every-num" v-for="item in xmjdList" :key="item.id"
                                     :style="{backgroundImage: 'url(' + item.back + ')'}">
                                    <p>{{item.title}}</p>
                                    <p>{{item.num}} <span>{{item.dw}}</span></p>
                                </div>
                            </div>
                            <img class="spjd-title" src="../assets/infoBack.png">

                            <div class="ydzq-box flex ac">
                                <div class="ydLeft">
                                    <p>用地项目周期：</p>
                                    <p><span style="font-size: 24px;font-weight: 700;text-align: left;color: #ffc41b;">6.8</span>个月
                                    </p>
                                </div>
                                <div class="ydMiddle"></div>
                                <div class="ydRight">
                                    <p>非用地项目：</p>
                                    <p><span style="font-size: 24px;font-weight: 700;text-align: left;color: #ffc41b;">4.1</span>个月
                                    </p>
                                </div>
                            </div>

                            <div class="all-tips flex ac jb">
                                <div class="every-tip" v-for="(item,index) in tipslist" :key="index">
                                    <p class="tt1">{{item.num1}}</p>
                                    <p class="tt2">{{item.title1}}</p>
                                    <p class="tt3">{{item.num2}}</p>
                                    <p class="tt4">{{item.title2}}</p>
                                </div>
                            </div>


                            <!--                            <div class="chart-three">-->
                            <!--                                <Echart-->
                            <!--                                        :options="options3"-->
                            <!--                                        id="threeChart"-->
                            <!--                                        height="184px"-->
                            <!--                                        width="392px"-->
                            <!--                                ></Echart>-->
                            <!--                            </div>-->
                            <!--                            <div class="zq-box flex ac">-->
                            <!--                                <img class="rili-icon" src="../assets/riliIcon.png">-->
                            <!--                                <p>审批阶段平均周期：</p>-->
                            <!--                                <p>3.5 <span>个月</span></p>-->
                            <!--                            </div>-->


                        </div>
                        <div class="mm2-box">
                            <img class="biaoti" src="../assets/infoBack.png" alt="">
                            <div class="all-js1 flex ac jb">
                                <div class="every-js" v-for="(item,index) in qubuList" :key="index">
                                    <p>{{item.title}}</p>
                                    <div class="back-yuan">
                                        <p>{{item.zhanbi}}</p>
                                    </div>
                                </div>
                            </div>
                            <img class="biaoti" src="../assets/infoBack.png" alt="">
                            <div class="all-js2 flex ac jb">
                                <div class="every-js" v-for="(item,index) in xinjianList" :key="index">
                                    <p>{{item.title}}</p>
                                    <div class="back-yuan">
                                        <p>{{item.zhanbi}}</p>
                                    </div>
                                </div>
                            </div>
                            <img class="biaoti" src="../assets/infoBack.png" alt="">
                            <div class="all-js3 flex ac jb">
                                <div class="every-js" v-for="(item,index) in xujianList" :key="index">
                                    <p>{{item.title}}</p>
                                    <div class="back-yuan">
                                        <p>{{item.zhanbi}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rr-box">
                            <img class="biaoti" src="../assets/infoBack.png" alt="">
                            <div class="jg-up flex ac jb">
                                <div class="jgup-left">
                                    <p class="mb10">竣工项目数量</p>
                                    <div class="jg-yuan">
                                        <p>15<span>个</span></p>
                                    </div>
                                </div>
                                <div class="jgup-right">

                                    <div class="all-yb flex fw">
                                        <div class="every-yb" v-for="(item,index) in jdList" :key="index">
                                            <div class="flex jb">
                                                <p style="margin-bottom: 7px;font-weight: 400">{{item.title}}</p>
                                                <p style="font-weight: 700">
                                                    {{((item.haveNum/item.total)*100).toFixed(2)}}%</p>
                                            </div>

                                            <div class="m-progress">
                                                <div class="m-progress-inner"
                                                     :style="`width: ${item.haveNum/item.total*100}%;`"></div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="jg-down">
                                <div class="table-info">
                                    <div class="table-header1 flex" v-if="jgIndex==0">
                                        <p style="padding-left: 3px">项目名称</p>
                                        <p class="">资料完整度</p>
                                        <p class="">预警</p>
                                    </div>
                                    <div class="table-header1 flex" v-if="jgIndex==1">
                                        <p style="padding-left: 3px">项目名称</p>
                                        <p class="">合同名称</p>
                                        <p class="">预警</p>
                                    </div>
                                    <div class="table-header1 flex" v-if="jgIndex==2">
                                        <p style="padding-left: 3px">项目名称</p>
                                        <p class="">延期送审</p>
                                        <p class="">预警</p>
                                    </div>
                                    <div class="all-table">
                                        <div class="flex ac" v-for="(item,index) in tableList" :key="index"
                                             @click="goInfo(item)"
                                             :class="item.type=='danger'?'danger-box': item.type=='warning' ? 'warning-box':'every-td '">
                                            <p style="padding-left: 3px">{{item.proName}}</p>
                                            <p class="">{{item.warnType}}</p>
                                            <p class="flex ac ">
                                                <img v-if="item.type=='danger'" src="../assets/infoBack.png">
                                                <img v-if="item.type=='warning'" src="../assets/infoBack.png">
                                                <span>{{item.warnObj}}</span>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="choose-box flex">
                                <div class="every-choose cr" :class="item.id==jgIndex?'jg-active':''"
                                     v-for="item in jgChooseList" :key="item.id" @click="chooseJg(item)">
                                    <p>{{item.title}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="down-box flex">
                        <div class="left-box flex ac">
                            <img class="gtqsTitle" src="../assets/infoBack.png" alt="">
                            <!-- // 底部第一个图表-->
                            <div>
                                <Echart
                                        :options="options4"
                                        id="bottom1Chart"
                                        height="204px"
                                        width="825px"
                                ></Echart>
                            </div>
                        </div>
                        <div class="left-box flex ac">
                            <img class="gtqsTitle" src="../assets/infoBack.png" alt="">
                            <!-- // 底部第一个图表-->
                            <div>
                                <Echart
                                        :options="options5"
                                        id="bottom2Chart"
                                        height="204px"
                                        width="825px"
                                ></Echart>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import drawMixin from "../utils/drawMixin";
    import {formatTime} from '../utils/index.js'
    import Echart from '@/common/echart'

    export default {
        mixins: [drawMixin],
        components: {
            Echart,
        },
        data() {
            return {
                timing: null,
                loading: true,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                options1: {},
                options2: {},
                project2: {
                    xAxisData: ['总数', '可研数', '初设数', '预算数', '招投标数'],
                    zijinData: [28, 25, 21, 14, 10],
                },
                xmjdList: [
                    {id: 0, title: '总数', num: '178', dw: '个', back: require('../assets/infoBack.png')},
                    {id: 1, title: '可研', num: '126', dw: '个', back: require('../assets/infoBack.png')},
                    {id: 2, title: '初设', num: '89', dw: '个', back: require('../assets/infoBack.png')},
                    {id: 3, title: '预算', num: '77', dw: '个', back: require('../assets/infoBack.png')},
                    {id: 3, title: '招投标', num: '56', dw: '个', back: require('../assets/infoBack.png')},
                ],
                tipslist: [
                    {id: 0, num1: '7.7', num2:'7.3', title1:'房建非标', title2:'房建一般',},
                    {id: 1, num1: '6.2', num2:'6.8', title1:'重大交通', title2:'四好农村路',},
                    {id: 2, num1: '6.7', num2:'5.1', title1:'用地市建', title2:'改造道路',},
                    {id: 3, num1: '7.1', num2:'6.9', title1:'公园非标', title2:'公园一般',},
                    {id: 4, num1: '4.2', num2:'', title1:' 改造提升', title2:'',},
                ],
                options3: {},
                project3: {
                    kzjgData: [5, 3, 2, 0, 0, 0, 0],
                    jiesuanData: [0, 0, 0, 2, 1, 2.5, 1],
                    // juesuanData: [15, 85, 52, 17, 75, 56, 41],
                },
                qubuList: [
                    {title: '投资完成率', zhanbi: '46.06%'},
                    {title: '项目开工率', zhanbi: '69.56%'},
                ],
                xinjianList: [
                    {title: '新建投资完成率', zhanbi: '17.60%'},
                    {title: '新建项目开工率', zhanbi: '24.72%'},
                ],
                xujianList: [
                    {title: '续建投资完成率', zhanbi: '53.05%'},
                    {title: '续建项目开工率', zhanbi: '96.61%'},
                ],
                jdList: [
                    {title: '资料完整度', haveNum: 85, total: 100},
                    {title: '合同送审率', haveNum: 79, total: 100},
                    {title: '决算送审率', haveNum: 90, total: 100},
                ],
                tableList: [
                    {id: 0, proName: '乔司街道方桥村老年公寓装修工程', warnType: '81%', warnObj: '黄色预警', type: 'warning'},
                    {id: 1, proName: '塘栖镇公租房工程', warnType: '83.5%', warnObj: '黄色预警', type: 'warning'},
                    {id: 2, proName: '新丰第二幼儿园', warnType: '61%', warnObj: '红色预警', type: 'danger'},
                ],
                tableList1: [
                    {id: 0, proName: '乔司街道方桥村老年公寓装修工程', warnType: '81%', warnObj: '黄色预警', type: 'warning'},
                    {id: 1, proName: '塘栖镇公租房工程', warnType: '83.5%', warnObj: '黄色预警', type: 'warning'},
                    {id: 2, proName: '新丰第二幼儿园', warnType: '61%', warnObj: '红色预警', type: 'danger'},
                ],
                tableList2: [

                    {id: 3, proName: '交警城东一中队营房改造工程', warnType: '建设工程监理合同', warnObj: '黄色预警', type: 'warning'},
                    {id: 4, proName: '交警城东一中队营房改造工程', warnType: '建设工程施工合同', warnObj: '黄色预警', type: 'warning'},
                    {id: 5, proName: '康泰支路（兴元路-康泰路）', warnType: '康泰支路（兴元路-康泰路）项目监理', warnObj: '红色预警', type: 'danger'},

                ],
                tableList3: [
                    {id: 6, proName: '乔司街道方桥村老年公寓装修工程', warnType: '延期送审2个月', warnObj: '黄色预警', type: 'warning'},
                    {id: 7, proName: '超山剑塘港提升改造工程', warnType: '延期送审1个月', warnObj: '黄色预警', type: 'warning'},
                    {id: 8, proName: '顺达路(绿洲路-新洲路)', warnType: '延期送审3个月', warnObj: '红色预警', type: 'danger'},
                ],

                jgChooseList: [
                    {id: 0, title: '资料完整度预警'},
                    {id: 1, title: '送审合同预警'},
                    {id: 2, title: '决算送审预警'}
                ],
                jgIndex: 0,
                options4: {},
                options5: {},
                project5: {
                    kzjgData: [136.9, 394.1, 93.5,31.3,30.8],
                    jiesuanData: [48.4,354.2,90.5,29.2 ,23.5],
                    juesuanData: [-13.0,287.7,55.9,28.9, 10.5]
                },

            }
        },

        mounted() {
            this.timeFn()
            this.cancelLoading(),
                this.getOneCharts(),
                this.getTwoCharts(),
                this.getThreeChart()
            this.getFourChart()
            this.getFiveChart()
        },
        beforeDestroy() {
            clearInterval(this.timing)
        },
        methods: {
            timeFn() {
                this.timing = setInterval(() => {
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                    this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                    this.dateWeek = this.weekday[new Date().getDay()]
                }, 1000)
            },
            cancelLoading() {
                setTimeout(() => {
                    this.loading = false
                }, 500)
            },
            chooseJg(item) {
                this.jgIndex = item.id
                if (item.id == 0) {
                    this.tableList = this.tableList1
                } else if (item.id == 1) {
                    this.tableList = this.tableList2
                } else if (item.id == 2) {
                    this.tableList = this.tableList3
                }
            },
            // 创建第一个图表
            getOneCharts() {
                this.options1 = {
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            name: '主体分类',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '40',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: 500, name: ''},
                                {value: 500, name: ''},
                                {value: 600, name: ''},
                            ]
                        }
                    ]
                }
            },
            // 创建第二个图表
            getTwoCharts() {
                this.options2 = {
                    color: ['#00FDF3'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        },
                        formatter: '<strong> {b0}</strong> <br /> <span style="display: inline-block; margin-right: 4px; border-radius: 10px; width: 10px; height: 10px;background-color: #5470c6;"></span><strong>{c0}</strong>个'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.project2.xAxisData,
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLabel: {
                                //x轴文字的配置
                                show: true,
                                interval: 0,//使x轴文字显示全
                            }
                        }
                    ],
                    yAxis: {
                        type: "value",
                        name: "(单位)个",
                        nameTextStyle: {
                            color: "#aaa",
                            nameLocation: "start",
                        },
                    },
                    series: [
                        {
                            name: '项目投资',
                            type: 'bar',
                            barWidth: '40%',
                            data: this.project2.zijinData
                        },

                    ],
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            formatter: '{c}'
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(17, 168,171, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(17, 168,171, 0.1)'
                            }]),
                            shadowColor: 'rgba(0, 0, 0, 0.1)',
                            shadowBlur: 10
                        }
                    }
                };
            },
            // 第三个图表
            getThreeChart() {
                this.options3 = {
                    backgroundColor: '#002657',
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '2%',
                        right: '4%',
                        bottom: '14%',
                        top: '16%',
                        containLabel: true
                    },
                    legend: {
                        data: ['用地基础设施', '非用地基础设施'],
                    },
                    xAxis: {
                        type: 'category',
                        data: ['用地基础设施类', '房建类', '产业类', '非用地基础设施类', '改造类', '环境提升类', '装修类'],
                        axisLine: {
                            lineStyle: {
                                color: 'white'
                            },

                        },
                        axisLabel: {
                            textStyle: {
                                fontFamily: 'Microsoft YaHei'
                            }
                        },
                    },

                    yAxis: {
                        type: 'value',
                        max: '10',
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: 'white'
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.3)'
                            }
                        },
                        axisLabel: {}
                    },
                    series: [{
                        name: '用地基础设施',
                        type: 'bar',
                        barWidth: '15%',
                        itemStyle: {
                            normal: {
                                color: '#00FAE3',
                                // barBorderRadius: 12,
                            },
                        },
                        data: this.project3.kzjgData
                    },
                        {
                            name: '非用地基础设施',
                            type: 'bar',
                            barWidth: '15%',
                            itemStyle: {
                                normal: {
                                    color: '#CC954C',
                                    // barBorderRadius: 11,
                                }

                            },
                            data: this.project3.jiesuanData
                        },
                    ]
                };
            },
            // 第四张图表
            getFourChart() {
                this.options4 = {
                    title: {
                        text: '增长率(%)'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['固定资产投资', '项目投资', '房地产开发投资', '杭州市固投']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: '固定资产投资',
                            type: 'line',
                            data: [0, 12.0, 16.6, 19.3,20.6]
                        },
                        {
                            name: '项目投资',
                            type: 'line',
                            data: [0, 26.7, 30.4, 21.8,18.5]
                        },
                        {
                            name: '房地产开发投资',
                            type: 'line',
                            data: [0, 2.9, 10.3, 17.6,22.2]

                        },{
                            name: '杭州市固投',
                            type: 'line',
                            data: [0, 13.51, 12.73, 10.65,10.35]

                        },
                    ]
                };
            },
            // 第五张图表
            // getFiveChart() {
            //     this.options5 = {
            //         title: {
            //             text: '金额（亿元）'
            //         },
            //         tooltip: {
            //             trigger: 'axis'
            //         },
            //         legend: {
            //             data: ['交通投资', '生态环境与公共设施投资', '高新技术产业投资', '民间投资(含房地产)', '制造业投资'],
            //             x: '20',
            //             y: 'top',
            //             padding: [0, 0, 30, 150],
            //         },
            //         grid: {
            //             left: '3%',
            //             right: '4%',
            //             bottom: '3%',
            //             containLabel: true
            //         },
            //         xAxis: {
            //             type: 'category',
            //             boundaryGap: false,
            //             data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            //         },
            //         yAxis: {
            //             type: 'value'
            //         },
            //         series: [
            //             {
            //                 name: '交通投资',
            //                 type: 'line',
            //                 data: [1.58, 1.58, 4.11, 8.07]
            //
            //             },
            //             {
            //                 name: '生态环境与公共设施投资',
            //                 type: 'line',
            //                 data: [8.87, 8.87, 15.01, 24.46,]
            //
            //             },
            //             {
            //                 name: '高新技术产业投资',
            //                 type: 'line',
            //                 data: [10.16, 10.16, 14.21, 19.14]
            //             }, {
            //                 name: '民间投资(含房地产)',
            //                 type: 'line',
            //                 data: [45.25, 45.25, 75.4, 90.51]
            //             },
            //             {
            //                 name: '制造业投资',
            //                 type: 'line',
            //                 data: [13.89, 13.89, 18.32, 23.35]
            //             }
            //         ]
            //     };
            // },

            getFiveChart() {
                this.options5 = {
                    title: {
                        text: '单位(%)'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        },
                        formatter: (params) => {
                            return params[0].name + '<br>' +
                                params[0].marker + ' ' + params[0].seriesName + ': ' + params[0].data + ' %' + '<br>' +
                                params[1].marker + ' ' + params[1].seriesName + ': ' + params[1].data + ' %' + '<br>' +
                                params[2].marker + ' ' + params[2].seriesName + ': ' + params[2].data + ' %';
                        },
                        // formatter: '<strong> {b0}</strong> <br /> <span style="display: inline-block; margin-right: 4px; border-radius: 10px; width: 10px; height: 10px;background-color: #5470c6;"></span><strong>{c0}</strong>个'
                    },
                    grid: {
                        left: '4%',
                        right: '4%',
                        bottom: '1%',
                        top: '26%',
                        containLabel: true
                    },
                    legend: {
                        data: ['三月', '四月', '五月'],
                        right: 30,
                        top: 0,
                        textStyle: {
                            color: '#fff'
                        },
                        itemWidth: 12,
                        itemHeight: 10
                        // itemGap: 35
                    },
                    xAxis: {
                        type: 'category',
                        data: ['交通投资', '生态环境与公共设施投资', '高新技术投资', '民间投资（含房地产）','制造业投资'],
                        axisLine: {
                            lineStyle: {
                                color: 'white'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            formatter:function(value){
                                let len = value.length;
                                let length = 6; //控制一行显示个数
                                let num = Math.ceil(len/length);//循环次数
                                if(num > 1){
                                    let str = '';
                                    for(let i = 0;i<num;i++){
                                        str += value.substring(i*length,(i+1)*length) + '\n';
                                    }
                                    return str;
                                }else{
                                    return value;
                                }
                            }
                        }
                    },

                    yAxis: {
                        type: 'value',
                        max: '400',
                        min: '-50',
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: 'white'
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.3)'
                            }
                        },
                        axisLabel: {}
                    },
                    series: [
                        {
                            name: '三月',
                            type: 'bar',
                            barWidth: '15%',
                            itemStyle: {
                                normal: {
                                    color: '#00E199'
                                }
                            },
                            data: this.project5.kzjgData
                        },
                        {
                            name: '四月',
                            type: 'bar',
                            barWidth: '15%',
                            itemStyle: {
                                normal: {
                                    color: '#FFC41B'
                                }
                            },
                            data: this.project5.jiesuanData
                        },
                        {
                            name: '五月',
                            type: 'bar',
                            barWidth: '15%',
                            itemStyle: {
                                normal: {
                                    color: '#00A5FF'
                                }
                            },
                            data: this.project5.juesuanData
                        }
                    ]
                }
            },
        },
    }
</script>

<style lang="scss">
    @import '../assets/scss/index.scss';
</style>
<style lang="scss">
    .anchorBL {
        display: none !important;
    }

    .dv-loading {
        margin-top: 25% !important;
    }
</style>
<style lang="scss" scoped>
    ::-webkit-scrollbar {
        display: none
    }

    /*标题图片样式*/
    .biaoti {
        display: block;
        width: 392px;
        height: 32px;
        margin: 0 auto;
    }

    .all-container {
        width: 1920px;
        height: 100%;
        background: url("../assets/infoBack.png") no-repeat;
        background-size: 100% 100%;
        color: #3CE6F0;

        .all-header {
            width: 1920px;
            height: 80px;
            background: url("../assets/headBack.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: space-between;

            .date-box {
                padding: 27px 20px;
                font-weight: 700;
                text-align: left;
                color: #00a5ff;

                .one {
                    font-size: 24px;
                    line-height: 28px;
                    margin-right: 12px;
                }

                .two {
                    font-size: 16px;
                    margin-right: 12px;
                }

                .three {
                    font-size: 16px;
                }
            }
        }

        .all-screen {
            width: 1864px;
            margin: 0 auto;
            padding-top: 15px;

            .up-box {
                .ll-box {
                    width: 460px;
                    height: 620px;
                    background: url("../assets/infoBack.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 76px 24px 0 24px;

                    .llOne-box {
                        .pro-num {
                            width: 230px;

                            position: relative;

                            p:first-child {
                                font-size: 14px;
                                font-weight: 400;
                                color: #00a5ff;
                                line-height: 20px;
                            }

                            p:nth-child(2) {
                                width: 172px;
                                height: 52px;
                                opacity: 0.5;
                                background: linear-gradient(0deg, #b5efff 0%, #1bb2dd);
                            }

                            p:last-child {
                                position: absolute;
                                top: 22px;
                                left: 8px;
                                font-size: 44px;
                                font-weight: 700;
                                text-align: left;
                                color: #ffffff;
                                line-height: 48px;
                            }
                        }
                    }

                    .ztfl-title {
                        height: 32px;
                        width: 394px;
                        margin: 24px auto 16px;
                    }

                    .xmtz-title {
                        height: 32px;
                        width: 394px;
                        margin: 24px auto 0px;
                    }

                    .llTwo-box {
                        padding: 0 20px;

                        .right {
                            .pt1 {
                                p:first-child {
                                    width: 5px;
                                    height: 5px;
                                    background: #00E199;
                                }

                                p:nth-child(2) {
                                    font-size: 14px;
                                    font-weight: 400;
                                    text-align: left;
                                    color: #ffffff;
                                    line-height: 20px;
                                }

                                p:nth-child(3) {
                                    font-size: 18px;
                                    font-weight: 700;
                                    text-align: left;
                                    color: #00e199;
                                    line-height: 24px;
                                }
                            }

                            .zj1 {
                                p:first-child {
                                    width: 5px;
                                    height: 5px;
                                    background: #FF9900;
                                }

                                p:nth-child(2) {
                                    font-size: 14px;
                                    font-weight: 400;
                                    text-align: left;
                                    color: #ffffff;
                                    line-height: 20px;
                                }

                                p:nth-child(3) {
                                    font-size: 18px;
                                    font-weight: 700;
                                    text-align: left;
                                    color: #FF9900;
                                    line-height: 24px;
                                }
                            }

                            .bm1 {
                                p:first-child {
                                    width: 5px;
                                    height: 5px;
                                    background: #00A5FF;
                                }

                                p:nth-child(2) {
                                    font-size: 14px;
                                    font-weight: 400;
                                    text-align: left;
                                    color: #ffffff;
                                    line-height: 20px;
                                }

                                p:nth-child(3) {
                                    font-size: 18px;
                                    font-weight: 700;
                                    text-align: left;
                                    color: #00A5FF;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }

                .mm1-box {
                    width: 460px;
                    height: 620px;
                    background: url("../assets/infoBack.png") no-repeat;
                    background-size: 100% 100%;
                    padding-top: 76px;

                    .xmjd-title, .spjd-title {
                        display: block;
                        width: 392px;
                        height: 32px;
                        margin: 0 auto;
                    }

                    .all-proNum {
                        width: 392px;
                        margin: 16px auto 32px;

                        .every-num {

                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            margin-top: 5px;

                            p {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: left;
                                color: #ffffff;
                                line-height: 20px;
                            }
                        }

                        .every-num:nth-child(1) {
                            width: 260px;
                            padding: 12px 23px 9px;
                        }

                        .every-num:nth-child(2), .every-num:nth-child(3), .every-num:nth-child(4), .every-num:nth-child(5) {
                            width: 128px;
                            padding: 12px 23px 9px;
                        }
                    }

                    .ydzq-box {
                        box-sizing: border-box;
                        width: 392px;
                        margin: 20px auto 0;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;

                        .ydLeft {
                            width: 160px;
                            height: 68px;
                            background: url("../assets/infoBack.png") no-repeat;
                            background-size: 100% 100%;
                            padding-left: 20px;
                            padding-top: 10px;
                            line-height: 25px;

                        }

                        .ydMiddle {
                            width: 84px;
                            height: 84px;
                            background: url("../assets/infoBack.png") no-repeat;
                            background-size: 100% 100%;
                        }

                        .ydRight {
                            width: 160px;
                            height: 68px;
                            background: url("../assets/infoBack.png") no-repeat;
                            background-size: 100% 100%;
                            padding-left: 20px;
                            padding-top: 10px;
                            line-height: 25px;

                        }
                    }

                    .all-tips {
                        width: 392px;
                        margin: 20px auto 0;
                        .every-tip {
                            width: 75px;
                            height: 144px;
                            background: url("../assets/infoBack.png") no-repeat;
                            background-size: 100% 100%;
                            text-align: center;
                            .tt1{
                                padding-top: 16px;
                                font-size: 24px;
                                font-weight: 700;
                                text-align: center;
                                color: #ffffff;
                            }
                            .tt2{
                                padding-top: 5px;
                                font-size: 14px;
                                font-weight: 400;
                                text-align: center;
                                color: #00a5ff;
                            }
                            .tt3{
                                padding-top: 35px;
                                font-size: 24px;
                                font-weight: 700;
                                text-align: center;
                                color: #ffffff;
                            }
                            .tt4{
                                padding-top: 5px;
                                font-size: 14px;
                                font-weight: 400;
                                text-align: center;
                                color: #00a5ff;
                            }
                        }
                    }

                    .chart-three {
                        width: 392px;
                        margin: 30px auto 0;
                    }

                    .zq-box {
                        width: 392px;
                        height: 40px;
                        background: linear-gradient(270deg, rgba(0, 165, 255, 0.00), rgba(0, 165, 255, 0.20));
                        box-shadow: 0px 1px 8px 0px rgba(0, 165, 255, 0.50) inset;
                        margin: 20px auto 0;

                        .rili-icon {
                            width: 20px;
                            height: 20px;
                            display: block;
                            margin-left: 15px;
                            margin-right: 15px;
                        }

                        p:first-child {
                            width: 126px;
                            font-size: 14px;
                            text-align: left;
                            color: #ffffff;
                            line-height: 20px;
                        }
                    ;

                        p:last-child {
                            font-size: 20px;
                            font-weight: 700;
                            text-align: left;
                            color: #ffc41b;

                            span {
                                font-size: 14px;
                                text-align: left;
                                color: #ffffff;
                            }
                        }
                    }


                }

                .mm2-box {
                    width: 460px;
                    height: 620px;
                    background: url("../assets/infoBack.png") no-repeat;
                    background-size: 100% 100%;
                    padding-top: 76px;

                    .all-js1 {
                        width: 352px;
                        margin: 25px auto;

                        .every-js {
                            text-align: center;
                            width: 161px;

                            p:first-child {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: center;
                                color: #00dcff;
                                margin-bottom: 8px;
                            }
                        ;

                            .back-yuan {
                                width: 161px;
                                height: 75.5px;
                                background: url("../assets/infoBack.png") no-repeat;
                                background-size: 100% 100%;

                                p {
                                    font-size: 24px;
                                    font-weight: 700;
                                    text-align: center;
                                    color: #ffffff;
                                }

                            }
                        }
                    }

                    .all-js2 {
                        width: 352px;
                        margin: 25px auto 25px;

                        .every-js {
                            text-align: center;
                            width: 161px;

                            p:first-child {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: center;
                                color: #00e199;
                                margin-bottom: 8px;
                            }
                        ;

                            .back-yuan {
                                width: 161px;
                                height: 75.5px;
                                background: url("../assets/infoBack.png") no-repeat;
                                background-size: 100% 100%;

                                p {
                                    font-size: 24px;
                                    font-weight: 700;
                                    text-align: center;
                                    color: #ffffff;
                                }

                            }
                        }
                    }

                    .all-js3 {
                        width: 352px;
                        margin: 25px auto 0;

                        .every-js {
                            text-align: center;
                            width: 161px;

                            p:first-child {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: center;
                                color: #FFC31A;
                                margin-bottom: 8px;
                            }
                        ;

                            .back-yuan {
                                width: 161px;
                                height: 75.5px;
                                background: url("../assets/infoBack.png") no-repeat;
                                background-size: 100% 100%;

                                p {
                                    font-size: 24px;
                                    font-weight: 700;
                                    text-align: center;
                                    color: #ffffff;
                                }

                            }
                        }
                    }
                }

                .rr-box {
                    width: 460px;
                    height: 620px;
                    background: url("../assets/infoBack.png") no-repeat;
                    background-size: 100% 100%;
                    padding-top: 76px;

                    .jg-up {
                        width: 392px;
                        height: 155px;
                        margin: 0 auto;

                        .jgup-left {
                            text-align: center;

                            .jg-yuan {
                                width: 161px;
                                height: 91px;
                                background: url("../assets/infoBack.png") no-repeat;
                                background-size: 100% 100%;

                                p {
                                    font-size: 37px;
                                    font-weight: 700;
                                    color: #ffffff;
                                    line-height: 42px;

                                    span {
                                        font-size: 16px;
                                    }

                                }
                            }
                        }

                        .jgup-right {
                            width: 208px;
                            padding-top: 10px;

                            .all-yb {
                                .every-yb {
                                    width: 208px;
                                    height: 42px;
                                    font-size: 16px;

                                    p {
                                        opacity: 0.8;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #ebecf2;
                                        line-height: 24px;

                                        span {
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: #2de5ff;
                                            line-height: 22px;
                                        }
                                    }

                                    .m-progress {
                                        position: relative;
                                        width: 208px;
                                        height: 6px;
                                        background: #35464E;
                                        margin-bottom: 20px;
                                        margin-right: 20px;

                                        .m-progress-inner {
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            height: 3px;
                                            /*background: #ffc107;*/
                                            /*border-bottom: rgba(45, 101, 188, 0.77) 3px dashed;*/
                                        }
                                    }
                                }

                                .every-yb:nth-child(1) {
                                    p {
                                    }

                                    .m-progress {
                                        .m-progress-inner {
                                            border-bottom: #00E199 6px dashed;
                                        }
                                    }
                                }

                                .every-yb:nth-child(2) {
                                    p {
                                    }

                                    .m-progress {
                                        .m-progress-inner {
                                            border-bottom: #FFC41B 6px dashed;
                                        }
                                    }
                                }

                                .every-yb:nth-child(3) {
                                    p {
                                    }

                                    .m-progress {
                                        .m-progress-inner {
                                            border-bottom: #00A5FF 6px dashed;
                                        }
                                    }
                                }
                            }


                        }

                    }

                    .jg-down {
                        width: 392px;
                        margin: 15px auto 10px;

                        .table-info {
                            padding: 0 12px;

                            .table-header1 {
                                width: 392px;
                                height: 30px;
                                background: #094b59;
                                border: 0.5px solid #3ea2b7;
                                border-radius: 4px;
                                line-height: 30px;

                                p:first-child {
                                    width: 200px;

                                }

                                p:nth-child(2) {
                                    width: 96px;

                                }

                                p:nth-child(3) {
                                    width: 96px;
                                }

                            }

                            .all-table {
                                width: 100%;
                                width: 392px;
                                height: 230px;
                                overflow-y: scroll;

                                .every-td {
                                    font-size: 12px;
                                    width: 392px;
                                    height: 30px;
                                    background: #17272d;
                                    border: 0.5px solid #07414c;
                                    border-radius: 4px;
                                    margin-top: 8px;

                                    p:first-child {
                                        width: 200px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    p:nth-child(2) {
                                        width: 96px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    p:nth-child(3) {
                                        width: 96px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }

                                .danger-box {
                                    font-size: 12px;
                                    width: 392px;
                                    height: 30px;
                                    background: #2d1717;
                                    border: 0.5px solid #f75432;
                                    border-radius: 4px;
                                    margin-top: 8px;
                                    color: #fd5534;

                                    .yuan {
                                        width: 60px;

                                        p {
                                            width: 25px;
                                            height: 25px;
                                            margin: 0 auto;
                                            border-radius: 50%;
                                            border: #fd5534 1px solid;
                                            line-height: 25px;
                                        }
                                    }

                                    p:first-child {
                                        width: 200px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    p:nth-child(2) {
                                        width: 96px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    p:nth-child(3) {
                                        img {
                                            width: 18px;
                                            height: 18px;
                                            margin-right: 5px;
                                        }

                                        width: 96px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                }

                                .warning-box {
                                    font-size: 12px;
                                    width: 392px;
                                    height: 30px;
                                    background: #221d0b;
                                    border: 0.5px solid #faad14;
                                    border-radius: 4px;
                                    color: #faad14;
                                    margin-top: 8px;

                                    .yuan {
                                        width: 60px;

                                        p {
                                            width: 25px;
                                            height: 25px;
                                            margin: 0 auto;
                                            border-radius: 50%;
                                            border: #faad14 1px solid;
                                            line-height: 25px;
                                        }
                                    }

                                    p:first-child {
                                        width: 200px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    p:nth-child(2) {
                                        width: 96px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    p:nth-child(3) {
                                        img {
                                            width: 18px;
                                            height: 18px;
                                            margin-right: 5px;
                                        }

                                        width: 96px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }

                            }
                        }
                    }

                    .choose-box {
                        align-items: center;
                        justify-content: center;
                        height: 24px;
                        text-align: center;
                        margin-top: 30px;

                        .every-choose {
                            background: #0f4085;
                            border-radius: 2px;
                            padding: 6px;
                        }

                        .jg-active {
                            background: #00ddff;
                            border-radius: 2px;
                            color: #203967;
                        }
                    }

                }
            }

            .down-box {
                margin-top: 32px;
                width: 1864px;
                height: 320px;
                background: url("../assets/infoBack.png") no-repeat;
                background-size: 100% 100%;
                padding-top: 76px;
                padding-left: 24px;
                padding-right: 24px;

                .left-box {
                    width: 932px;
                    height: 244px;

                    .gtqsTitle {
                        width: 40px;
                        height: 204px;
                    }

                }

                .right-box {
                    width: 932px;
                    height: 244px;

                }
            }
        }
    }

</style>

<style lang="scss">
    .dv-percent-pond text {
        display: none;
    }

    .table2 .dv-scroll-board .header {
        font-size: 12px !important;
    }

    .table2 .dv-scroll-board .header .header-item {
        padding: 0 5px !important;
    }
    .mr10 {
        margin-right: 10px;
    }

    .tc {
        text-align: center;
    }

    .mt15 {
        margin-top: 15px;
    }

    .flex {
        display: flex;
    }

    .ac {
        align-items: center;
    }

    .jb {
        justify-content: space-between;
    }

    .fw {
        flex-wrap: wrap;
    }

    .bbox {
        box-sizing: border-box;
    }

    .cr {
        cursor: pointer;
    }

    pdlf24 {
        padding: 0 24px;
    }

    .mr10 {
        margin-right: 10px;
    }

    .mt25 {
        margin-top: 25px;
    }

    .mb25 {
        margin-bottom: 25px;
    }

    .mb10 {
        margin-bottom: 10px;
    }
</style>
